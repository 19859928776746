import { Route } from "react-router-dom";
import { appConfig } from "app/config";
// Utils
import generateKey from "shared/utils/generateKey";
// Layouts
import AuthLayout from "pages/layouts/auth-layout";
// Containers
import LoginContainer from "pages/containers/auth/login-container";
import RegisterContainer from "pages/containers/auth/register-container";
import ForgotPasswordContainer from "../../pages/containers/auth/forgot-password-container";
import ResetPasswordContainer from "../../pages/containers/auth/reset-password-container";
import ConfirmEmailContainer from "../../pages/containers/auth/confirm-email-container";

const authRoutes = [
    <Route key={generateKey()} element={<AuthLayout />} >
        <Route path={appConfig.pages.login.path} element={<LoginContainer />} />        
        {appConfig.userCanRegister && <Route path={appConfig.pages.register.path} element={<RegisterContainer />} />}
        <Route path={appConfig.pages.forgotPassword.path} element={<ForgotPasswordContainer />} />
        <Route path={appConfig.pages.resetPassword.path} element={<ResetPasswordContainer />} />  
        <Route path={appConfig.pages.confirmEmail.path} element={<ConfirmEmailContainer />} /> 
    </Route>
]

export default authRoutes;