import { ChartModes } from "shared/symbol/constants";
import storageManager from "modules/storage-manager";

let chartMode = ChartModes.SINGLE;

if (storageManager.preferences.get()?.chartMode === 0) { chartMode = ChartModes.MULTI }

debugger;

const chartState = {
    tabs: storageManager.preferences.get()?.chartTabs || ['EURUSD'],
    selectedTab: storageManager.preferences.get()?.chartSelectedTab || 0,
    mode: chartMode
}

export default chartState;