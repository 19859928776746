import React from 'react';
import useTranslation from 'modules/localization';
import Api, { ApiTypes } from 'api';
import { appConfig } from 'app/config';
import { connect } from 'react-redux';
import { accountService } from 'services';
import generateKey from 'shared/utils/generateKey';
import storeManager from 'store/manager';
import { AccountLoginInformations, AccountTraderNo, AccountUser } from 'store/slices/account-slice/types';
import { RootState } from 'store/types';
import { authService } from 'services';
import IMAGES from 'assets/images';

interface Props {
  loginInformations: AccountLoginInformations,
  user: AccountUser,
  traderNo: AccountTraderNo,
  dispatch: Function
}

const TradingAccounts = (props: Props) => {
    const brokers: ApiTypes.BrokerSummaryDto[] = props.loginInformations.data.brokers;
    const allowTradingPlatformOperations = props.user?.allowTradingPlatformOperations; 
    const { t } = useTranslation();

    const handleLoginClick = (data: { platformCode: string, accountNo: number }) => {
        accountService.getTradingAccountInformations(data)
        .then(() => {
            storeManager.account.setTraderNo(data.accountNo);
            storeManager.app.setLocation(appConfig.pages.trader.path);
        });
    }

    /** Description: If login length is one, logs in the account automatically. */
    const redirecting = accountService.loginIfHasOnlyOneTradingAccount((tradingAccount) => {
        handleLoginClick({ accountNo: tradingAccount.login, platformCode: tradingAccount.code });
    })

    if (redirecting) {
        return null;
    }

    const logins = accountService.getLogins();

    return (
      <div className="trading-accounts">
        <div onClick={authService.logout} className="close-button">
            <IMAGES.ICON.LOGOUT.ReactComponent />
        </div>
        <div className="accounts">
            {
              logins.length || allowTradingPlatformOperations?
                brokers.map((broker, brokerIndex) => {
                    return (
                        <div key={broker.name} className="broker">
                            <div className="title">{broker.name}</div>
                                {
                                    broker.tradingPlatforms!.map((tradingPlatform, tradingPlatformIndex) => {
                                        return (
                                            <div key={generateKey()} className="platform">
                                                {
                                                    (tradingPlatform.logins?.length || (tradingPlatform.serverType === Api.EnServerType.Demo && allowTradingPlatformOperations)) &&
                                                    <div className="code">{tradingPlatform.code}</div>
                                                }
                                                <ul>
                                                    {
                                                        tradingPlatform.logins!.map((accountNo) => {
                                                                const platformCode = tradingPlatform.code || '';
                                                                
                                                            return (
                                                                <li key={accountNo}>
                                                                    <span><i className="icofont-user-alt-7"></i>{accountNo}</span>
                                                                    <button className="s-outline-button" onClick={() => handleLoginClick({ platformCode, accountNo })}>{t('Login')}</button>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        (tradingPlatform.serverType === Api.EnServerType.Demo && allowTradingPlatformOperations && appConfig.userCanCreateDemoAccount) &&
                                                            <li>
                                                                <button className="p-outline-button" onClick={() => accountService.createDemoTradingAccount(brokerIndex, tradingPlatformIndex)}><i className="icofont-plus"></i> {t('CreateDemoAccount')}</button>
                                                            </li>
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    )
                })
                : 
                (
                    <span className='no-found'>
                        {t('AccountNotFound')}
                    </span>
                )
          }
        </div>
      </div>
    )
}

const mapStateToProps = (state: RootState) => ({
  loginInformations: state.account.loginInformations,
  user: state.account.user,
  traderNo: state.account.traderNo,
})

export default connect(mapStateToProps)(TradingAccounts);